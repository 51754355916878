* {
    margin: 0px;
    box-sizing: border-box;
    font-family: Noto Sans;
}

/* prevent drag */
body {
    background-color: #CAE2FE;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}